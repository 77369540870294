// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-backend-tsx": () => import("./../../../src/pages/jobs/backend.tsx" /* webpackChunkName: "component---src-pages-jobs-backend-tsx" */),
  "component---src-pages-jobs-supply-chain-lead-tsx": () => import("./../../../src/pages/jobs/supply-chain-lead.tsx" /* webpackChunkName: "component---src-pages-jobs-supply-chain-lead-tsx" */)
}

